<template>
  <div class="p-grid">
    <div class="p-col-12 p-md-6">
      <h3><i class="pi pi-user" style="font-size: 1.6rem"></i> Perfil</h3>
    </div>
  </div>

  <div class="p-grid">

    <!-- Formulário de Alteração do Usuário -->
    <div class="p-col-12 p-md-5" >
      <div class="card p-fluid">

        <form class="p-grid" autocomplete="no">
          <div class="p-field p-md-6">
            <label for="nome">Nome</label>
            <InputText id="nome" type="text" v-model="nome" autocomplete="no" />
          </div>
          
          <div class="p-field p-md-6">
            <label for="senha1">Senha Atual</label>
						<Password id="senha1" v-model="senha" :feedback="false" autocomplete="no" toggleMask></Password>
          </div>

          <div class="p-field p-md-6">
            <label for="senha2">Nova Senha</label>
						<Password id="senha2" v-model="senhaNova" :feedback="false" autocomplete="no" toggleMask></Password>
          </div>

          <div class="p-field p-md-6">
            <label for="senha3">Confirmar Nova Senha</label>
						<Password id="senha3" v-model="senhaConf" :feedback="false" autocomplete="no" toggleMask></Password>
          </div>

					<div class="p-md-12">
            <div class="p-grid">
              <div class="p-md-12">
								<Button
									id="buttonAlterarUsuario"
									label="Alterar Usuário"
									icon="pi pi-check"
									class="p-button-success"
									@click="alterar"
								></Button>
							</div>
						</div>
					</div>

        </form>
      </div>
    </div>
  </div>

  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />
  
</template>

<script>

export default {
  data() {
    return {

      // Campos do Formulário
      nome: "",
      senha: "",
      senhaNova: "",
      senhaConf: "",
  
    };
  },

  mounted() {
    this.nome = this.$auth.currentUser.name;
  },

  methods: {

    alterar() {

      const self = this;

      var erro = false;
      
      if(self.nome.trim() == "") {
        self.$toast.add({severity:'error', summary: 'ERRO!', detail: "Seu nome deve ser informado!", life: 5000});
        erro = true;
      }

      //Alteração de senha
      if(self.senhaNova != "") {

        if(self.senha.trim() == "") {
          self.$toast.add({severity:'error', summary: 'ERRO!', detail: "A senha atual deve ser informada!", life: 5000});
          erro = true;
        }

        //Checar se senhas digitadas são iguais
        if(self.senhaNova != self.senhaConf) {
          self.$toast.add({severity:'error', summary: 'ERRO!', detail: "A Nova Senha e Confirmação da Nova Senha são diferentes!", life: 5000});
          erro = true;
        }

      }
      
      if(erro)
        return false;

      var dados = {
        'name' : self.nome,
        'password' : self.senha,
        'password_new' : self.senhaNova
      }

      this.$api.post('/update-profile', dados).then(function (response) {
       
        if(response.data.success) {
          self.$toast.add({severity:'success', summary: 'Sucesso!', detail: response.data.message, life: 3000});
        } else {
          self.$toast.add({severity:'error', summary: 'ERRO!', detail: response.data.message, life: 5000});
        }

      }).catch(function (erro) {

        if(typeof erro.response != "undefined"){
          self.$toast.add({severity:'error', summary: 'ERRO!', detail: erro.response.data.message, life: 5000});
        }

      });

    }
  }
}
</script>

<style scoped>
</style>